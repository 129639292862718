<template>
  <div class="added-to-cart"
       :class="{ logged: this.$store.getters['auth/signedIn'] }"
       v-if="this.$store.getters['order/getOrder']">
    <loader :loading="this.$store.getters['order/loading']"/>

    <div class="arrow">
      <div class="empty"/>
    </div>

    <div class="head">
      My Cart
    </div>

    <div class="cart">
      <div class="line-item"
           v-for="line_item in cartPreviewLineItems"
           :data-line-item-dimensions="JSON.stringify(line_item.variant.dimensions_properties)">
        <div>
          <img class="responsive image-preview"
               :src="line_item.variant.image"
               :alt="line_item.variant.image_alternative_text"
               v-if="line_item.variant.image">
        </div>

        <div>
          <component :is="getLineItemLinkComponent(line_item)"
                     :to="getLineItemLinkObject(line_item)"
                     class="name"
                     v-on:click.native="hideCart">
            {{ line_item.product_name }}
            <div class="options"
                 v-for="option in line_item.variant.options">
              <span class="name">
                {{ option.name }}:
              </span>
              <span class="value">
                {{ option.value }}
              </span>
            </div>

            <div v-if="getLineItemDimensions(line_item)"
                 class="dimensions">
              Dimensions: {{ getLineItemDimensions(line_item) }}
            </div>

            <div class="backorder"
                 v-if="getBackorderable(line_item.variant)">
              Backorder
            </div>

            <div class="price">
              <div v-if="line_item.sale_display"
                   class="on-sale">
                {{ formatPrice(line_item.total_price) }}
                <span>{{ formatPrice(line_item.total_price_without_sale) }}</span>
              </div>
              <div v-else>
                {{ formatPrice(line_item.total_price) }}
              </div>
            </div>
          </component>
        </div>

        <div v-if="isVirtualLineItem(line_item)" />
        <div v-else
             class="quantity">
          <div class="group">
            <div class="manage-input-wrapper">
              <div class="manage-input"
                   role="button"
                   aria-label="change quantity"
                   @click="changeQuantityBtn(false, line_item.id)">-</div>
              <div class="amount">{{ line_item.quantity }}</div>
              <div class="manage-input"
                   :class="{ disabled: !canAddMoreItems(line_item) }"
                   role="button"
                   aria-label="change quantity"
                   v-tooltip="canAddMoreItems(line_item) ? null : 'No more items available'"
                   @click="changeQuantityBtn(true, line_item.id)">+
              </div>
            </div>

            <i class="fas fa-trash-alt trash"
               role="button"
               aria-label="remove item"
               @click="removeItem(line_item.id)"></i>
          </div>
        </div>
      </div>
    </div>

    <div class="summary">
      <div class="wrapper-right">
        <div class="key">
          Sub-total:
        </div>
        <div class="val">
          {{ formatPrice(this.$store.getters['order/getLineItemsTotal']) }}
        </div>
      </div>

      <div class="wrapper-right"
           v-if="this.discountValue() > 0">
        <div class="key">
          Discount:
        </div>
        <div class="val val--green">
          -{{ formatPrice(this.discountValue()) }}
        </div>
      </div>

      <div v-if="shippingCostVisible()"
           class="wrapper-right">
        <div class="key">
          Shipping:
        </div>
        <div class="val">
          {{ this.shippingCost() == 0 ? 'Free' : formatPrice(this.shippingCost()) }}
        </div>
      </div>

      <div class="wrapper-right">
        <div class="key">
          Total:
        </div>
        <div class="val">
          {{ formatPrice( this.totalWithShippingCost() ) }}
        </div>
      </div>
    </div>

    <div class="buttons"
         style="padding: 10px 20px">
      <div class="btn"
           role="button"
           @click="goToCart">
        proceed to cart
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader'
import PriceMixin from '@/../shared/mixins/price'
import CordialMixin from '@/mixins/cordial'
import LineItemDimensionsMixin from '@/mixins/lineItemDimensions'
import CheckoutLineItemsMixin from '@/mixins/checkoutLineItems'

export default {
  name: 'cart-preview',
  components: {
    Loader
  },
  mixins: [
    PriceMixin,
    CordialMixin,
    LineItemDimensionsMixin,
    CheckoutLineItemsMixin
  ],
  methods: {
    totalWithShippingCost() {
      return Math.max(
        parseFloat(this.$store.getters['order/getLineItemsTotal']) +
        this.shippingCost() -
        parseFloat(this.discountValue()),
        0.0
      )
    },
    discountValue() {
      return parseFloat(this.$store.getters['order/getDiscountsAdjustmentsTotal'])
    },
    hideCart() {
      this.$emit('manageDisplayCart', false)
      this.$router.go()
    },
    goToCart() {
      this.$emit('manageDisplayCart', false)
      this.$router.push({ name: 'cart' })
    },
    goToCheckout() {
      this.$emit('manageDisplayCart', false)
      this.$router.push({ name: 'checkout' })
    },
    changeQuantityBtn(add, line_item_id) {
      let line_items = this.$store.getters['order/getLineItems']
      let line_item = line_items.filter(li => li.id == line_item_id)[0]

      if (add && !this.canAddMoreItems(line_item)) {
        return
      }

      if (add === false && line_item['quantity'] - 1 === 0) {
        this.$store.dispatch('modal/showModal', {
          component: 'ConfirmationAlert',
          props: {
            warning: "Do you want to remove this item from your cart?",
            confirmText: "yes, remove",
            confirmFunction: () => {
              this.$store.dispatch('order/removeItem', line_item_id)
              this.$store.dispatch('modal/hideModal')
              this.cordialUpdateCart()
            },
            rejectText: "no, keep the item",
            rejectFunction: () => this.$store.dispatch('modal/hideModal')
          }
        })
      } else {
        this.$store.dispatch('order/changeQuantityBtn', { add: add, line_item_id: line_item_id })
        this.cordialUpdateCart()
      }
    },
    shippingCost() {
      if (!this.shippingCostVisible()) return 0

      return parseFloat(this.$store.getters['order/getShipmentsTotal'])
    },
    shippingCostVisible () {
      return this.$store.getters['order/getIsFinalShippingCost']
    },
    getBackorderable(variant) {
      return variant.backorderable && variant.variant_quantity == 0
    },
    removeItem(line_item_id) {
      this.$store.dispatch('modal/showModal', {
        component: 'ConfirmationAlert',
        props: {
          warning: "Do you want to remove this item from your cart?",
          confirmText: "yes, remove",
          confirmFunction: () => {
            this.$store.dispatch('order/removeItem', line_item_id)
            this.$store.dispatch('modal/hideModal')
            this.cordialUpdateCart()
          },
          rejectText: "no, keep the item",
          rejectFunction: () => this.$store.dispatch('modal/hideModal')
        }
      })
    },
    cordialUpdateCart() {
      let cartData = this.$store.getters['order/getCartCordial']
      if (!!cartData) this.modifyCart(cartData)
    },
    canAddMoreItems(line_item) {
      return (line_item.variant.variant_quantity > line_item.quantity) ||
        line_item.variant.backorderable ||
                    line_item.variant.made_to_order ||
                    line_item.gift_card
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "@/assets/stylesheets/variables";

.added-to-cart {
  background: #fff;
  position: absolute;
  transform: translateX(calc(-100% + 20px));
  border: 1px solid #cccccc;
  z-index: 99998;
  width: 600px;
  max-width: 500px;

  margin-top: 24px;

  .arrow {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 11px 25px 11px;
    border-color: transparent transparent #cccccc transparent;
    line-height: 0px;
    _border-color: #000000 #000000 #cccccc #000000;
    _filter: progid:DXImageTransform.Microsoft.Chroma(color='#cccccc');

    position: absolute;
    top: -25px;
    right: -1px;

    @media only screen and (max-width: 600px) {
      right: 15px;
    }

    .empty {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 25px 10px;
      border-color: transparent transparent #fff transparent;
      line-height: 0px;
      _border-color: #000000 #000000 #fff #000000;
      _filter: progid:DXImageTransform.Microsoft.Chroma(color='#fff');

      position: absolute;
      right: -10px;
    }
  }

  @media only screen and (max-width: 600px) {
    width: 300px;
    transform: translateX(calc(-100% + 42px));

    &.logged {
      transform: translateX(calc(-100% + 120px));

      .arrow {
        right: 92px;
      }
    }
  }

  @media only screen and (min-width: 600px) and (max-width: 768px) {
    width: 400px;
    transform: translateX(calc(-100% + 20px));
  }

  .head {
    padding: 10px 20px;
    font-family: Tenez;
    font-size: 2.4rem;
    line-height: 1.21;
    letter-spacing: 1.2px;
    color: #151515;

    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 10px;

    .close {
      color: #2e2e2e;
      cursor: pointer;
    }
  }

  .cart {
    border-top: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    height: 400px;
    max-height: 400px;
    overflow: auto;
    scrollbar-color: #5f6a7b #cccccc;

    .line-item {
      display: grid;
      grid-template-columns: 100px 1fr auto;
      grid-gap: 10px;
      border-bottom: 1px solid #cccccc;
      padding: 10px;
      margin: 10px 0;

      @media only screen and (max-width: 600px) {
        grid-template-columns: 100px 1fr;

        .quantity {
          grid-row: 2;
          grid-column: 2/3;
        }
      }

      &:last-child {
        border: none;
      }

      .image-preview {
        border: 1px solid #cccccc;
        padding: 2px;
      }

      .name {
        font-family: Tenez;
        font-size: 1.8rem;
        line-height: 1.22;
        letter-spacing: 0.9px;
        color: #151515;

        text-decoration: none;

        &:hover,
        &:focus,
        &:visited {
          text-decoration: none;
        }
      }

      .options,
      .description,
      .backorder,
      .dimensions {
        margin: 4px 0;
        font-family: OpenSans;
        font-size: 1.2rem;
        line-height: 1.42;
        letter-spacing: 0.6px;
        color: #151515;
      }

      .options {

        .name,
        .value {
          font-family: OpenSans;
          text-transform: capitalize;
          font-size: 1.2rem;
        }
      }

      .price {
        font-family: OpenSans;
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 1.38;
        letter-spacing: 0.8px;
        color: #151515;

        .on-sale {
          color: $color-sale;

          span {
            padding-left: 10px;
            font-size: 1.4rem;
            font-family: "OpenSans Light";
            text-decoration: line-through;
            color: #989797;
            font-weight: bold;
          }
        }
      }

      .quantity {

        .group {
          display: inline-flex;
          font-size: 1.6rem;
          white-space: nowrap;

          .manage-input,
          .amount {
            border: 1px solid #cccccc;
            width: 35px;
            height: 35px;
            display: inline-flex;
            vertical-align: middle;
            font-size: 2rem;
            align-items: center;
            justify-content: center;
          }

          .amount {
            cursor: default;
            font-size: 1.6rem;
            color: #212121;
          }

          .manage-input {
            cursor: pointer;
            font-style: bold;
            color: #6c6c6c;

            &:hover {
              background: #ddd4cf;
            }
          }

          .amount {
            border-right: none;
            border-left: none;
          }

          .manage-input-wrapper {
            display: inline-flex;
            margin-right: 5px;
          }

          .manage-input {
            color: #6c6c6c;
            font-style: bold;
            font-size: 2rem;
            cursor: pointer;
            align-items: center;
            justify-content: center;

            &.disabled {
              cursor: not-allowed;
              opacity: .5;
              background-color: transparent;
            }
          }

          .trash {
            font-size: 2rem;
            color: #4e4e4e;
            width: 35px;
            height: 35px;
            display: inline-flex;
            vertical-align: middle;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }

      }

    }
  }

  .summary {
    display: grid;
    grid-template-columns: 150px 1fr;
    grid-gap: 10px;
    padding: 10px 20px;
    justify-items: stretch;

    .wrapper-left {
      grid-column: 1/2;
    }

    .wrapper-right {
      grid-column: 1/3;

      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .key {
      display: inline-block;
      font-family: Tenez;
      font-size: 2rem;
      line-height: 1.2;
      letter-spacing: 1px;
      text-align: left;
      color: #151515;
    }

    .val {
      display: inline-block;
      font-family: OpenSans;
      font-size: 2rem;
      font-weight: bold;
      line-height: 1.35;
      letter-spacing: 1px;
      text-align: right;
      color: #000;

      &--green {
        color: $sap-green;
      }
    }

    @media only screen and (max-width: 600px) {
      grid-template-columns: 1fr 1fr;

      .wrapper-right,
      .wrapper-left {
        grid-template-columns: 1fr;
        grid-column: 1/3;

        .key {
          grid-column: 1/2;
        }

        .val {
          grid-column: 2/3;
        }
      }
    }

  }

  .btn {
    font-size: 1.6rem;

    &.checkout {
      background: #5f6a7b;
    }
  }
}
</style>
